import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import { CSSProperties } from "react";
import { Link as RouterLink } from "react-router-dom";
import { MainLayout } from "../components/Layouts/MainLayout";
import { theme } from "../theme/theme";
import styles from "./SignupPage.module.scss";

const cityName: CSSProperties = {
    fontSize: "17px",
};

const HandleLearnMoreClick = () => {
    document
        .getElementById("learnMore")
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
};
const SignupPage = () => {
    return (
        <MainLayout>
            <Card sx={{ padding: { xs: 0, marginTop: "30px" } }}>
                <Box style={{ position: "relative" }}>
                    <Box
                        component="div"
                        style={{
                            height: "100%",
                            position: "absolute",
                            backgroundImage: `url('/images/signup-bg.jpg')`,
                            backgroundSize: "cover",
                            opacity: "0.25",
                            width: "100%",
                            zIndex: 0,
                            overflow: "hidden",
                        }}
                    >
                        &nbsp;
                    </Box>
                    <CardContent
                        className={styles.mainContent}
                        sx={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: { xl: "1250px", xs: "100%" },
                            maxWidth: { lg: "1250px" },
                            padding: { xs: "20px", lg: "0" },
                        }}
                    >
                        <Grid container justifyContent="center" spacing={3}>
                            <Grid item xs={12} md={8}>
                                <Stack direction="column" spacing={4}>
                                    <Typography
                                        variant="header01"
                                        textAlign="center"
                                    >
                                        Unleash your creativity
                                    </Typography>
                                    <Typography
                                        variant="header02"
                                        textAlign="left"
                                    >
                                        Build Your Event from Start to Finish
                                        with Our Powerful Event Creator Tools!
                                    </Typography>
                                    <Typography
                                        variant="body01"
                                        className={styles.description}
                                    >
                                        Our platform is designed to make event
                                        planning and execution seamless and
                                        stress-free. Ready to create an
                                        unforgettable event? Sign up now to
                                        start using our powerful event creator
                                        tools and bring your vision to life!
                                    </Typography>

                                    <Stack
                                        direction="column"
                                        alignItems="center"
                                        style={{
                                            width: "100%",
                                            marginTop: "10vw",
                                        }}
                                    >
                                        <Link
                                            component={RouterLink}
                                            to="https://portal.nrby.io/sign-up"
                                        >
                                            <Button variant="large">
                                                <Typography variant="subheader01">
                                                    Sign Up Now
                                                </Typography>
                                            </Button>
                                        </Link>

                                        <Link
                                            component={RouterLink}
                                            to="#learnMore"
                                            style={{ marginTop: "10vw" }}
                                            onClick={HandleLearnMoreClick}
                                        >
                                            <Stack alignItems="center">
                                                <Typography variant="subheader02">
                                                    Learn More
                                                </Typography>
                                                <svg
                                                    width="26"
                                                    height="13"
                                                    viewBox="0 0 26 13"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 1L13 11L25 1"
                                                        stroke="white"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                    />
                                                </svg>
                                            </Stack>
                                        </Link>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Stack
                            spacing={{ xs: 1, sm: 4 }}
                            mt={{ xs: 2, sm: 4 }}
                            className={styles.subheadersBlock}
                        ></Stack>
                    </CardContent>
                </Box>
                <Stack style={{ paddingTop: "40px" }} id="learnMore">
                    <Box sx={{ padding: { xs: "20px", lg: "0" } }}>
                        <Stack
                            alignItems="center"
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "75%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginBottom: "50px",
                                },
                            }}
                        >
                            <Typography
                                variant="header02"
                                style={{
                                    marginTop: "30px",
                                    marginBottom: "30px",
                                    textAlign: "center",
                                }}
                            >
                                Start Planning Your Event Today!
                            </Typography>
                            <Grid container>
                                <Grid
                                    item
                                    xs={5}
                                    alignItems="right"
                                    style={{ paddingRight: "20px" }}
                                >
                                    <Stack>
                                        <Stack
                                            direction="row"
                                            justifyContent="right"
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="header01"
                                                sx={{
                                                    fontSize: {
                                                        xs: "40px",
                                                        md: "80px",
                                                    },
                                                }}
                                            >
                                                Step&nbsp;
                                            </Typography>
                                            <Typography
                                                style={{
                                                    color: theme.palette
                                                        .nrbyColors.tangerine,
                                                }}
                                                variant="header01"
                                                sx={{
                                                    fontSize: {
                                                        xs: "40px",
                                                        md: "80px",
                                                    },
                                                }}
                                            >
                                                01
                                            </Typography>
                                        </Stack>
                                        <Typography
                                            variant="body01"
                                            style={{
                                                textAlign: "right",
                                                display: "inline-block",
                                            }}
                                        >
                                            Sign up for an account and verify
                                            your identity.
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={styles.dividerStart}
                                >
                                    <img src="/images/step-circle.png" />
                                </Divider>
                                <Grid item xs={5} alignItems="left"></Grid>

                                <Grid item xs={5} alignItems="left"></Grid>
                                <Divider orientation="vertical" flexItem>
                                    <img src="/images/step-circle.png" />
                                </Divider>
                                <Grid
                                    item
                                    xs={5}
                                    alignItems="left"
                                    style={{ paddingLeft: "20px" }}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="left"
                                        alignItems="center"
                                    >
                                        <Typography
                                            style={{
                                                color: theme.palette.nrbyColors
                                                    .tangerine,
                                            }}
                                            sx={{
                                                fontSize: {
                                                    xs: "40px",
                                                    md: "80px",
                                                },
                                            }}
                                            variant="header01"
                                        >
                                            02&nbsp;
                                        </Typography>
                                        <Typography
                                            variant="header01"
                                            sx={{
                                                fontSize: {
                                                    xs: "40px",
                                                    md: "80px",
                                                },
                                            }}
                                        >
                                            Step
                                        </Typography>
                                    </Stack>
                                    <Typography variant="body01">
                                        After you’ve registered, configure your
                                        preferences. Don’t worry, we’ll help you
                                        with our onboarding process!
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={5}
                                    alignItems="right"
                                    style={{ paddingRight: "20px" }}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="right"
                                        alignItems="center"
                                    >
                                        <Typography
                                            variant="header01"
                                            sx={{
                                                fontSize: {
                                                    xs: "40px",
                                                    md: "80px",
                                                },
                                            }}
                                        >
                                            Step&nbsp;
                                        </Typography>
                                        <Typography
                                            style={{
                                                color: theme.palette.nrbyColors
                                                    .tangerine,
                                            }}
                                            sx={{
                                                fontSize: {
                                                    xs: "40px",
                                                    md: "80px",
                                                },
                                            }}
                                            variant="header01"
                                        >
                                            03
                                        </Typography>
                                    </Stack>
                                    <Typography
                                        variant="body01"
                                        style={{
                                            textAlign: "right",
                                            display: "inline-block",
                                        }}
                                    >
                                        Link your new Nrby Events account with
                                        our partner payment system, Stripe.
                                    </Typography>
                                </Grid>
                                <Divider orientation="vertical" flexItem>
                                    <img src="/images/step-circle.png" />
                                </Divider>
                                <Grid item xs={5} alignItems="left"></Grid>

                                <Grid item xs={5} alignItems="left"></Grid>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={styles.dividerEnd}
                                >
                                    <img src="/images/step-circle.png" />
                                </Divider>
                                <Grid
                                    item
                                    xs={5}
                                    alignItems="left"
                                    style={{ paddingLeft: "20px" }}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="left"
                                        alignItems="center"
                                    >
                                        <Typography
                                            style={{
                                                color: theme.palette.nrbyColors
                                                    .tangerine,
                                            }}
                                            sx={{
                                                fontSize: {
                                                    xs: "40px",
                                                    md: "80px",
                                                },
                                            }}
                                            variant="header01"
                                        >
                                            04&nbsp;
                                        </Typography>
                                        <Typography
                                            variant="header01"
                                            sx={{
                                                fontSize: {
                                                    xs: "40px",
                                                    md: "80px",
                                                },
                                            }}
                                        >
                                            Step
                                        </Typography>
                                    </Stack>
                                    <Typography variant="body01">
                                        Plan and create your first event! We
                                        don’t charge event creators for hosting
                                        events and if your tickets are free your
                                        users don’t pay anything either! If you
                                        need any help a live person will be your
                                        guide.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                    <Box
                        style={{ backgroundColor: "#FFFFFF" }}
                        sx={{ padding: { xs: "20px", lg: "0 0 60px 20px" } }}
                    >
                        <Stack
                            sx={{ width: { xs: "100%", md: "80%" } }}
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "40px",
                            }}
                            alignItems="center"
                        >
                            <Typography
                                variant="header02"
                                style={{
                                    color: theme.palette.nrbyColors
                                        .invertedColor,
                                    textAlign: "center",
                                }}
                            >
                                Maximizing Your Event Potential
                            </Typography>

                            <Grid
                                container
                                spacing={2}
                                sx={{ marginTop: { xs: "20px", md: "40px" } }}
                            >
                                <Grid item xs={12} sm={6} md={3}>
                                    <Stack className={styles.benefitsCard}>
                                        <img
                                            src="/images/benefit-ticketing.png"
                                            style={{ width: "160px" }}
                                        />
                                        <Typography
                                            variant="header02"
                                            style={{
                                                color: theme.palette.nrbyColors
                                                    .invertedColor,
                                                textAlign: "center",
                                            }}
                                        >
                                            Ticketing
                                        </Typography>
                                        <Typography
                                            variant="largeBody01"
                                            style={{
                                                marginTop: "20px",
                                                color: theme.palette.nrbyColors
                                                    .invertedColor,
                                            }}
                                        >
                                            By offering the most competitive
                                            rates in the industry, we help you
                                            and your attendees save a
                                            considerable amount of money on
                                            fees. This means that you'll have
                                            more money at your disposal.
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Stack className={styles.benefitsCard}>
                                        <img
                                            src="/images/benefit-analytics.png"
                                            style={{ width: "160px" }}
                                        />
                                        <Typography
                                            variant="header02"
                                            style={{
                                                color: theme.palette.nrbyColors
                                                    .invertedColor,
                                                textAlign: "center",
                                            }}
                                        >
                                            Analytics
                                        </Typography>
                                        <Typography
                                            variant="largeBody01"
                                            style={{
                                                marginTop: "20px",
                                                color: theme.palette.nrbyColors
                                                    .invertedColor,
                                            }}
                                        >
                                            Uncertain about the timing of your
                                            event? Our utilization of key
                                            metrics can aid you in determining
                                            and optimizing the likelihood of
                                            achieving success.
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Stack className={styles.benefitsCard}>
                                        <img
                                            src="/images/benefit-integration.png"
                                            style={{ width: "160px" }}
                                        />
                                        <Typography
                                            variant="header02"
                                            style={{
                                                color: theme.palette.nrbyColors
                                                    .invertedColor,
                                                textAlign: "center",
                                            }}
                                        >
                                            Integration
                                        </Typography>
                                        <Typography
                                            variant="largeBody01"
                                            style={{
                                                marginTop: "20px",
                                                color: theme.palette.nrbyColors
                                                    .invertedColor,
                                            }}
                                        >
                                            Create HTML code that can be
                                            inserted into your website to
                                            display your upcoming events to your
                                            audience and provide a link to
                                            purchase tickets!
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Stack className={styles.benefitsCard}>
                                        <img
                                            src="/images/benefit-calendar.png"
                                            style={{ width: "160px" }}
                                        />
                                        <Typography
                                            variant="header02"
                                            style={{
                                                color: theme.palette.nrbyColors
                                                    .invertedColor,
                                                textAlign: "center",
                                            }}
                                        >
                                            Management
                                        </Typography>
                                        <Typography
                                            variant="largeBody01"
                                            style={{
                                                marginTop: "20px",
                                                color: theme.palette.nrbyColors
                                                    .invertedColor,
                                            }}
                                        >
                                            Take charge of your event using Nrby
                                            and enhance your reach to numerous
                                            individuals within your vicinity.
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                    <Box>
                        <Grid
                            container
                            style={{
                                backgroundColor:
                                    theme.palette.nrbyColors.invertedBgColor,
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                md={6}
                                style={{
                                    paddingTop: "60px",
                                    paddingBottom: "60px",
                                    backgroundColor:
                                        theme.palette.nrbyColors.tangerine,
                                }}
                            >
                                <Stack
                                    direction="column"
                                    spacing={10}
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="header02"
                                        style={{ textAlign: "center" }}
                                    >
                                        Covering events across the nation
                                    </Typography>
                                    <img
                                        src="/images/map.png"
                                        style={{
                                            maxWidth: "100%",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                        }}
                                    />
                                    <Grid
                                        container
                                        sx={{
                                            padding: {
                                                xs: "20px",
                                                lg: "0 0 60px 20px",
                                            },
                                        }}
                                        rowSpacing={2}
                                    >
                                        <Grid item xs={0} lg={1}></Grid>
                                        <Grid item xs={6} md={4} lg={2}>
                                            <Typography
                                                variant="subheader02"
                                                style={cityName}
                                            >
                                                Chicago
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={2}>
                                            <Typography
                                                variant="subheader02"
                                                style={cityName}
                                            >
                                                Dallas
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={2}>
                                            <Typography
                                                variant="subheader02"
                                                style={cityName}
                                            >
                                                Las Vegas
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={2}>
                                            <Typography
                                                variant="subheader02"
                                                style={cityName}
                                            >
                                                Los Angeles
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={2}>
                                            <Typography
                                                variant="subheader02"
                                                style={cityName}
                                            >
                                                Miami
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={0} lg={1}></Grid>
                                        <Grid item xs={0} lg={1}></Grid>
                                        <Grid item xs={6} md={4} lg={2}>
                                            <Typography
                                                variant="subheader02"
                                                style={cityName}
                                            >
                                                Minneapolis
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={2}>
                                            <Typography
                                                variant="subheader02"
                                                style={cityName}
                                            >
                                                Nashville
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={2}>
                                            <Typography
                                                variant="subheader02"
                                                style={cityName}
                                            >
                                                New York
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={2}>
                                            <Typography
                                                variant="subheader02"
                                                style={cityName}
                                            >
                                                San Francisco
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={3}>
                                            <Typography
                                                variant="subheader02"
                                                style={cityName}
                                            >
                                                Washington D.C.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                style={{
                                    paddingTop: "60px",
                                    paddingBottom: "60px",
                                    backgroundColor:
                                        theme.palette.nrbyColors
                                            .invertedBgColor,
                                    position: "relative",
                                }}
                            >
                                <Box
                                    component="div"
                                    style={{
                                        height: "100%",
                                        position: "absolute",
                                        backgroundImage: `url('/images/signup-benefits-bg.png')`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        opacity: "1",
                                        width: "100%",
                                        zIndex: 0,
                                        overflow: "hidden",
                                        filter: "brightness(0.2)",
                                    }}
                                >
                                    &nbsp;
                                </Box>
                                {/* <CardMedia style={{}} component="img" image={TopImage} /> */}
                                <CardContent
                                    className={styles.mainContent}
                                    sx={{
                                        marginLeft: "auto",
                                        marginRight: "auto",

                                        padding: { xs: "20px", lg: "0" },
                                    }}
                                >
                                    <Stack
                                        direction="column"
                                        alignItems="center"
                                        spacing={10}
                                    >
                                        <Typography
                                            variant="header01"
                                            style={{
                                                color: theme.palette.nrbyColors
                                                    .primaryColor,
                                            }}
                                            sx={{
                                                fontSize: {
                                                    xs: "40px",
                                                    md: "80px",
                                                },
                                            }}
                                        >
                                            Unlock the Benefits
                                        </Typography>

                                        <Stack direction="column" spacing={5}>
                                            <Stack direction="row" spacing={5}>
                                                <img
                                                    src="/images/benefit-calendar-small.png"
                                                    style={{ width: "29px" }}
                                                />
                                                <Typography variant="subheader02">
                                                    Event Manager
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={5}>
                                                <img
                                                    src="/images/benefit-ticketing-small.png"
                                                    style={{ width: "29px" }}
                                                />
                                                <Typography variant="subheader02">
                                                    Ticketing
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={5}>
                                                <img
                                                    src="/images/benefit-sales-small.png"
                                                    style={{ width: "29px" }}
                                                />
                                                <Typography variant="subheader02">
                                                    Sales Tax & Entertainment
                                                    Tax Processing
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={5}>
                                                <img
                                                    src="/images/benefit-integration-small.png"
                                                    style={{ width: "29px" }}
                                                />
                                                <Typography variant="subheader02">
                                                    Website Integration
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={5}>
                                                <img
                                                    src="/images/benefit-analytics-small.png"
                                                    style={{ width: "29px" }}
                                                />
                                                <Typography variant="subheader02">
                                                    Standard Analytics
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={5}>
                                                <img
                                                    src="/images/benefit-predictive-analytics-small.png"
                                                    style={{ width: "29px" }}
                                                />
                                                <Typography variant="subheader02">
                                                    Predictive Analytics (beta)
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Stack
                            sx={{ padding: { xs: "20px", lg: "0" } }}
                            style={{
                                backgroundColor:
                                    theme.palette.nrbyColors.invertedBgColor,
                                paddingTop: "120px",
                                paddingBottom: "80px",
                            }}
                            spacing={7}
                        >
                            <Typography
                                variant="header02"
                                style={{
                                    color: theme.palette.nrbyColors
                                        .invertedColor,
                                    textAlign: "center",
                                }}
                            >
                                Stay in the Know and Signup For Our Newsletter
                                Today!
                            </Typography>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="center"
                                justifyItems="center"
                            >
                                <Grid item xs={0} md={2}></Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography
                                        variant="subheader02"
                                        style={{
                                            color: theme.palette.nrbyColors
                                                .invertedColor,
                                        }}
                                    >
                                        By signing up, you'll be the first to
                                        know about any new developments and
                                        updates regarding our event creator
                                        information, including upcoming events
                                        and exclusive content. Don't miss out on
                                        the latest news and insights - stay in
                                        the loop by subscribing to our
                                        newsletter today.
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={2}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Link
                                        component={RouterLink}
                                        to="https://portal.nrby.io/sign-up"
                                    >
                                        <Button variant="large">
                                            <Typography variant="subheader01">
                                                Sign Up Now
                                            </Typography>
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                    <Box sx={{ padding: { xs: "20px", lg: "0" } }}>
                        <Stack
                            style={{
                                backgroundColor:
                                    theme.palette.nrbyColors.primaryBgColor,
                                paddingTop: "60px",
                                paddingBottom: "80px",
                            }}
                            spacing={7}
                            alignItems="center"
                        >
                            <Typography
                                variant="header02"
                                style={{ textAlign: "center" }}
                            >
                                Our Trusted Partners
                            </Typography>
                            <Typography variant="headliner">
                                “Brands We Work With to Bring You the Best"
                            </Typography>
                            <Stack
                                direction="row"
                                spacing={10}
                                useFlexGap
                                flexWrap="wrap"
                            >
                                <Box
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <Link
                                        component={RouterLink}
                                        to="https://www.vizzyhardseltzer.com/"
                                        target="_blank"
                                    >
                                        <img
                                            src={"/images/partners/1 Vizzy.png"}
                                            alt="Vizzy"
                                            style={{ width: "100%" }}
                                        />
                                    </Link>
                                </Box>
                                <Box
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <Link
                                        component={RouterLink}
                                        to="https://www.coorslight.com/en-US"
                                        target="_blank"
                                    >
                                        <img
                                            src={"/images/partners/2 Coors.png"}
                                            alt="Coors"
                                            style={{ width: "100%" }}
                                        />
                                    </Link>
                                </Box>
                                <Box
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <Link
                                        component={RouterLink}
                                        to="https://redlocks.com/"
                                        target="_blank"
                                    >
                                        <img
                                            src={
                                                "/images/partners/3 Red Locks.png"
                                            }
                                            alt="Red Locks"
                                            style={{ width: "100%" }}
                                        />
                                    </Link>
                                </Box>

                                <Box
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <Link
                                        component={RouterLink}
                                        to="https://www.topochicohardseltzerusa.com/"
                                        target="_blank"
                                    >
                                        <img
                                            src={"/images/partners/4 Topo.png"}
                                            alt="Topo"
                                            style={{ width: "100%" }}
                                        />
                                    </Link>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box
                        style={{
                            backgroundColor: theme.palette.nrbyColors.tangerine,
                            paddingTop: "60px",
                            paddingBottom: "80px",
                        }}
                        sx={{ padding: { xs: "20px", lg: "0" } }}
                    >
                        <Stack
                            style={{
                                maxWidth: "1100px",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                            spacing={7}
                            alignItems="center"
                        >
                            <Typography
                                variant="header02"
                                style={{
                                    color: theme.palette.nrbyColors
                                        .primaryColor,
                                    textAlign: "center",
                                }}
                            >
                                Sneak Peek: "Exciting Upcoming Features to
                                Improve Your Experience"
                            </Typography>
                            <Typography
                                variant="headliner"
                                style={{ textTransform: "none" }}
                            >
                                We are constantly working on new features and
                                improvements to enhance your experience and make
                                our product even better. We can't wait to share
                                these new developments with you and are
                                committed to continually providing you with the
                                best possible product. Stay tuned for more
                                updates and keep an eye out for these exciting
                                features! Here's a sneak peek of what's in
                                store:
                            </Typography>
                            <Stack
                                direction="row"
                                spacing={10}
                                useFlexGap
                                flexWrap="wrap"
                            >
                                <Stack direction="row" alignItems="center">
                                    <img src="/images/sneakpeek-icon.png" />
                                    <Typography
                                        variant="subheader02"
                                        style={{ verticalAlign: "middle" }}
                                    >
                                        Sponsorship Hub
                                    </Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <img src="/images/sneakpeek-icon.png" />
                                    <Typography
                                        variant="subheader02"
                                        style={{ verticalAlign: "middle" }}
                                    >
                                        Merchandising
                                    </Typography>
                                </Stack>

                                <Stack direction="row" alignItems="center">
                                    <img src="/images/sneakpeek-icon.png" />
                                    <Typography
                                        variant="subheader02"
                                        style={{ verticalAlign: "middle" }}
                                    >
                                        Venue Booking
                                    </Typography>
                                </Stack>

                                <Stack direction="row" alignItems="center">
                                    <img src="/images/sneakpeek-icon.png" />
                                    <Typography
                                        variant="subheader02"
                                        style={{ verticalAlign: "middle" }}
                                    >
                                        Marketing Tools
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Card>
        </MainLayout>
    );
};

export default SignupPage;
