import { AgeRestrictionCode } from "../../types/models/enums";

const AgeRestriction = (ageRestriction: AgeRestrictionCode) => {
    switch (ageRestriction) {
        case AgeRestrictionCode.None:
            return <span>No Restrictions</span>;
        case AgeRestrictionCode.LegalAdult:
            return <span>Legal Adult</span>;
        case AgeRestrictionCode.TwentyOnePlus:
            return <span>Twenty One Plus</span>;
    }
};

export default AgeRestriction;
